"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.asFunction = exports.selectionTypeWithOptions = exports.selectionType = exports.singleSelectionType = exports.isoUtcDateType = exports.dashDateType = exports.arrayOf = exports.oauthSecretType = exports.fileType = exports.booleanType = exports.passwordType = exports.yamlType = exports.htmlType = exports.jsType = exports.jsonType = exports.makeIntType = exports.intType = exports.descriptionType = exports.makeStringType = exports.stringType = exports.assertIsIntParameterType = exports.assertIsStringParameterType = exports.hiddenValue = void 0;
function hiddenValue(value, hide) {
    if (!hide) {
        return undefined;
    }
    else {
        return function (config) {
            if (hide(config)) {
                return typeof value === "function" ? value(config) : value;
            }
            else {
                return undefined;
            }
        };
    }
}
exports.hiddenValue = hiddenValue;
function assertIsPrimitiveParameterTypeName(typeName, errorMsg) {
    if (typeName.startsWith("array/"))
        throw new Error(errorMsg || "Primitive parameter assertion failed");
}
function assertIsStringParameterType(parameterType, errorMessage) {
    if (parameterType.typeName !== "string")
        throw new Error(errorMessage || "`string` parameter type assertion failed");
}
exports.assertIsStringParameterType = assertIsStringParameterType;
function assertIsIntParameterType(parameterType, errorMessage) {
    if (parameterType.typeName !== "int")
        throw new Error(errorMessage || "`int` parameter type assertion failed");
}
exports.assertIsIntParameterType = assertIsIntParameterType;
exports.stringType = {
    typeName: "string",
};
var makeStringType = function (options) {
    var result = __assign({}, exports.stringType);
    return __assign(__assign({}, exports.stringType), options);
};
exports.makeStringType = makeStringType;
exports.descriptionType = {
    typeName: "description",
};
exports.intType = {
    typeName: "int",
};
var makeIntType = function (options) {
    var result = __assign({}, exports.intType);
    if (options)
        Object.entries(options).forEach(function (_a) {
            var key = _a[0], value = _a[1];
            return (result[key] = value);
        });
    return result;
};
exports.makeIntType = makeIntType;
exports.jsonType = {
    typeName: "json",
};
exports.jsType = {
    typeName: "javascript",
};
exports.htmlType = {
    typeName: "html",
};
exports.yamlType = {
    typeName: "yaml",
};
exports.passwordType = {
    typeName: "password",
};
exports.booleanType = {
    typeName: "boolean",
};
exports.fileType = {
    typeName: "file",
};
exports.oauthSecretType = {
    typeName: "oauthSecret",
};
var arrayOf = function (param) {
    var typeName = param.typeName;
    assertIsPrimitiveParameterTypeName(typeName);
    return {
        typeName: "array/".concat(typeName),
    };
};
exports.arrayOf = arrayOf;
/**
 * YYYY-MM-DD
 */
exports.dashDateType = {
    typeName: "dashDate",
};
/**
 * ISO_8601 (https://en.wikipedia.org/wiki/ISO_8601) time
 */
exports.isoUtcDateType = {
    typeName: "isoUtcDate",
};
var singleSelectionType = function (options) {
    return (0, exports.selectionType)(options, 1);
};
exports.singleSelectionType = singleSelectionType;
var selectionType = function (options, maxOptions) {
    return (0, exports.selectionTypeWithOptions)(options.map(function (id) { return ({ displayName: id, id: id }); }), maxOptions);
};
exports.selectionType = selectionType;
var selectionTypeWithOptions = function (options, maxOptions) {
    return {
        data: {
            options: options,
            maxOptions: maxOptions,
        },
        typeName: "selection",
    };
};
exports.selectionTypeWithOptions = selectionTypeWithOptions;
function asFunction(p) {
    if (typeof p === "function") {
        return p;
    }
    else {
        return function (_) { return p; };
    }
}
exports.asFunction = asFunction;
